.page-item {
  cursor: pointer;
}
.backdrop {
  background-color: rgba(0,0,0,0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}
td {
  white-space: nowrap;
}

th.cursor-orden {
  cursor : pointer;
}
